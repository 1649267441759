import React from "react";
import '../styles/herojoin.css'; 
const Hero = () => {
  return (
    <div>
      <div class="pagetop pagetop1">
        <div class="container ">
          <h2 class="page-title"> Join Jiliko </h2>
        </div>
      </div>
    </div>
  );
};

export default Hero;
