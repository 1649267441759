import React from 'react'

const CreateNew = () => {
  return (
    <section class="bg-grey">
    <div class="container main ">
      <p class="p center title">How To Create A New jiliko Account?</p>
      <p class="p w1 w2 center ">Step1. Click registration button</p>
      <p class="p center">To register for the JILIKO Registry, you must click the "Register" button in the top right
        corner of this page. It's quick and easy to complete, so we ask that you please make sure you have read
        through all the information before hitting submit.</p>
      <img src="	https://jiliko.ph/resource/images/join-img2.jpg" class="img_fit center-img" alt='images'/>
      <div class="flex flex-md flex-md-row-reverse mt-2">
        <div class="col-55 col-md-12 col-R">
          <p class="p w1 w2 ">Step2. Fill out the information</p>
          <p class="p">The registry requires the following information: </p>
          <ul class="spot">
            <li><span class="w1"> UserName:</span> Your username must be at least 5 characters that begin with a
              letter, number, or scale are very important and require at least 6 characters.</li>
            <li><span class="w1"> Password:</span> Please keep your password confidential.</li>
            <li><span class="w1"> Mobile number: </span>Please provide a valid Mobile number to easily submit
              important information such as product information</li>
          </ul>
          <p class="p w1 w2 ">Step 3. Complete the registration</p>
          <p class="p">Your account will be completely open during this time, but you must ensure that you are 18
            years or older under this Agreement.</p>
        </div>
        <div class="col-45 col-md-12">
          <img src="	https://jiliko.ph/resource/images/join-img3.jpg" class="img_fit center-img" alt='images'/>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CreateNew