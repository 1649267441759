import React from 'react'

const Hero = () => {
  return (
    <div class="pagetop pagetop4">
        <div class="container ">
            <h2 class="page-title"> Live Casino</h2>
        </div>
    </div>
  )
}

export default Hero