import React from 'react'

const HowtoPlay = () => {
  return (
    <div class="container main ">
            <p class="p center title"> How To Play The<span class="w1"> Fish Game </span> </p>
            <p class="p center mb-3">Fish shooting games can be played by two to four players. Even though tanks and
                cannons are used in the game they are not necessary. The basic idea behind fish shooting games is
                relatively simple. A group of players (usually four players) shoot fish with cannons for rewards. The
                cannons are placed on different sides of the gameboard, and everyone shoots the same pool of fish. A key
                decision for players is to decide which fish to shoot. This is because the fish vary in terms of rarity,
                “health,” and speed – meaning it is much easier to kill a small fish for fewer rewards than a large fish
                for bigger rewards. Fish also float in and out of the board, so it is important to keep track of whether
                the fish has just entered the board or is about to slip out.</p>
            <div class="flex bg-grey mb-2">
                <div class="col-3 col-md-12">
                    <img src="	https://jiliko.ph/resource/images/fish-img1.jpg" class="img_fit col-12" alt="images"/>
                </div>
                <div class="col-9 col-md-12 ">
                    <div class="content">
                        <span class="w1 w2"> Common fish </span>
                        <p class="p">These fish are the most frequent small schools of fish in the game, or lower
                            scoring fish that are easy to knock down and from which players can easily score points.</p>
                    </div>
                </div>
            </div>
            <div class="flex bg-grey mb-2">
                <div class="col-3 col-md-12">
                    <img src="	https://jiliko.ph/resource/images/fish-img2.jpg" class="img_fit col-12" alt="images"/>
                </div>
                <div class="col-9 col-md-12 ">
                    <div class="content">
                        <span class="w1 w2"> Prop fish </span>
                        <p class="p">These fish carry a lot of special props, if you want to get this special prop, then
                            you must try to take it down.</p>
                    </div>
                </div>
            </div>
            <div class="flex bg-grey mb-2">
                <div class="col-3 col-md-12">
                    <img src="	https://jiliko.ph/resource/images/fish-img3.jpg" class="img_fit col-12" alt="images"/>
                </div>
                <div class="col-9 col-md-12 ">
                    <div class="content">
                        <span class="w1 w2"> Special Fish </span>
                        <p class="p">The special fish species is the second most difficult of the boss fish, and the
                            appearance of this fish species is different from the normal fish, it will be similar to the
                            theme of the game.</p>
                    </div>
                </div>
            </div>
            <div class="flex bg-grey mb-2">
                <div class="col-3 col-md-12">
                    <img src="	https://jiliko.ph/resource/images/fish-img4.jpg" class="img_fit col-12" alt="images"/>
                </div>
                <div class="col-9 col-md-12 ">
                    <div class="content">
                        <span class="w1 w2"> The Boss Fish </span>
                        <p class="p">appears differently in different scenes, and although it has a high score, it is
                            not easy to defeat.</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-md ">
                <div class="col-6 col-md-12 col-R">
                    <p class="title  p">Some <span class="w1">Tips to Win </span>the Fisher Game </p>
                    <p class="p">When playing fish shooting games, we often fail to hit the fish we want because they
                        are blocked by the fish near us, so we must pay attention to the surrounding fish, small fish
                        with a small cannon to hit, large fish and fish with a large cannon to hit, too far away do not
                        go to hit, so as not to waste shells. If you are new to the game, it is recommended that you
                        play the fish near you, and then the game will slowly increase the difficulty, there will be
                        sharks and other more advanced fish, you can use the accumulated energy to fire the energy
                        cannon to target the kind of very advanced and difficult to hit the species, but also relatively
                        will not consume the number of shells.</p>
                </div>
                <div class="col-6 col-md-12"><img src="	https://jiliko.ph/resource/images/fish-img5.jpg"
                        class="img_fit" alt="images"/> </div>
            </div>
        </div>
  )
}

export default HowtoPlay