import React from "react";

const Banner = () => {
  return (
    <div>
      <div class="banner">
        <div class="container">
          <div class="banner-block flex flex-md">
            <div class="col-6 col-md-12">
              <h2 class="banner-title">
                <span class="w-main">A BETTER WAY TO PLAY!</span>
                Jiliko is the Best Bonuses Online casino in Philippines.
              </h2>
              <p class="p">
                {" "}
                <a
                  href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
                  target="_blank"
                  rel="noreferrer"
                >
                  Jiliko
                </a>{" "}
                is one of the leading casinos in the Philippines, offering fast
                cashouts. In this casino you can play the most popular video
                games on your mobile device (iPhone and Android), including
                slots and poker hands simulation! The site has been cited by
                several leading media outlets as one of the best slot machine
                game developers in multiple categories.
              </p>
              <a
                class="btn"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
                target="_blank"
                rel="noreferrer"
              >
                JILIKO Login & Sign up
              </a>
            </div>
            <div class="col-6 col-md-12">
              {" "}
              <img
                src="https://jiliko.ph/resource/images/jiliko-banner.webp"
                class="img_fit center-img"
                alt="images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
