import React from 'react'

const PlayFishGames = () => {
  return (
    <section class="fish-bg bg-cover w-white">
    <div class="container main center">
        <p class="p mt-3 title">Play fish games and<span class="w-main"> Experience new challenges </span></p>
        <p class="p mb-3">Are you one of the many people who think that fish games are an exciting gambling
            option? Do you love feeling in control of your own destiny? Well, you may not believe it, but our
            JiliKo fish shooting games mean that you can actually prove it!</p>
    </div>
</section>
  )
}

export default PlayFishGames