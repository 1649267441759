import React from 'react'
import Hero from '../components/FisherGames/Hero'
import ShootingGames from '../components/FisherGames/ShootingGames'
import FishingGames from '../components/FisherGames/FishingGames'
import HowtoPlay from '../components/FisherGames/HowtoPlay'
import PlayFishGames from '../components/FisherGames/PlayFishGames'

const FisherGames = () => {
  return (
    <div><Hero/>
    <main>
      <ShootingGames/>
      <FishingGames/>
      <HowtoPlay/>
      <PlayFishGames/>
      
      </main></div>
  )
}

export default FisherGames