import React from 'react'

const OnlineSabong = () => {
  return (
    <div class="container main ">
    <div class="flex ">
        <div class="col-9 col-md-12">
            <p class="title  p"> <span class="w1"> How To Play </span> Online Sabong </p>
            <p class="p">Sabong is a quick game , two cocks are assigned to 2 parties. Players can choose Meron
                (red), Wala (blue), or Draw (TIE) which have 3 side to bet on. The minimum bet amount is ₱
                100.The order is ₱ 200, ₱ 500, ₱ 1000 and ₱ 2000. Players can adjust their amount according to
                their budget that makes Sabong a very exciting game to play.</p>
            <img src="	https://jiliko.ph/resource/images/sabong-img3.jpg" class="img_fit center-img" alt='images'/>
            <p class="p">When you play sabong, there are some common betting terms that you need to know. You
                also need to know the following betting terms when it comes to how to play online sabong.</p>
        </div>
        <div class="col-3 col-md-12 col-R">
            <p class="p w1 w2">The language of betting odds</p>
            <table class="table-main">
                <tr>
                    <th>Parehas</th>
                    <td>Even odds</td>
                </tr>
                <tr>
                    <th>Lo dies</th>
                    <td>Logro diez<br/>
                        100 wins 125<br/>
                        200 wins 250<br/>
                        400 wins 500
                    </td>
                </tr>
                <tr>
                    <th>Walo-anim</th>
                    <td>300 wins 400<br/>
                        600 wins 800
                    </td>
                </tr>
                <tr>
                    <th>Onse </th>
                    <td>400 wins 550<br/>
                        800 wins 1100
                    </td>
                </tr>
                <tr>
                    <th>Tres</th>
                    <td>1000 wins 1500</td>
                </tr>
                <tr>
                    <th>Sampu-anim</th>
                    <td>600 wins 1000</td>
                </tr>
                <tr>
                    <th>Doblado</th>
                    <td>1000 wins 2000</td>
                </tr>
            </table>
        </div>
    </div>
</div>
  )
}

export default OnlineSabong