// import Header from './components/header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/App.css";
import Layout from "./components/layout/main-layout";
import Home from "./pages/Home";

import FisherGames from "./pages/FisherGames";
import JoinJiliko from "./pages/joinJiloko";
import CasinoPage from "./pages/Casino";
import Nopages from "./pages/Nopages";
import SlotPage from "./pages/Slot";
import Sport from "./pages/Sport";
import Sabong from "./pages/sabong";
import Arcade from "./pages/Arcade";

function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              {/* <Route index element={<} /> */}
              <Route path="/"  element={<Home />} />
              <Route path="/joinJiliko" element={<JoinJiliko />} />
              <Route path="/slot" element={<SlotPage />} />
              <Route path="/fisher-games" element={<FisherGames />} />
              <Route path="/casino" element={<CasinoPage />} />
              <Route path="/sport" element={<Sport />} />
              <Route path="/sabong" element={<Sabong />} />
              <Route path="/Arcade" element={<Arcade />} />
              <Route path="/Promotion" element={<Sabong />} />
              <Route path="*" element={<Nopages />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>{" "}
      {/* <Footer/> */}
    </>
  );
}

export default App;
