import React from "react";

const MostPopular = () => {
  return (
    <div class="container main ">
      <p class="p center title">
        {" "}
        Most<span class="w1">popular slot</span> games at JILIKO
      </p>
      <ul class="flex row center mb-3">
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img
              data-cfsrc="resource/images/slot/01.jpg"
              class="img_fit"
              alt="super ace"
            />
            <img
              src="	https://jiliko.ph/resource/images/slot/01.jpg"
              class="img_fit"
              alt="super ace"
            />
            <p class="mask">Super Ace</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img
              data-cfsrc="resource/images/slot/02.png"
              class="img_fit"
              alt="golden empire"
            />
            <img
              src="https://jiliko.ph/resource/images/slot/02.png"
              alt="golden empire"
              class="img_fit"
            />
            <p class="mask">Golden Empire</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img
              data-cfsrc="resource/images/slot/03.png"
              class="img_fit"
              alt="fortune gems"
            />
            <img
              src="https://jiliko.ph/resource/images/slot/03.png
                        "
              alt="fortune gems"
              class="img_fit"
            />
            <p class="mask">Fortune Gems</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/04.png" class="img_fit" alt="Boxing King" />
            <img
              src="https://jiliko.ph/resource/images/slot/04.png
                    " alt="Boxing King"
              class="img_fit"
            />
            <p class="mask">Boxing King</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/05.jpg" class="img_fit" alt="Chinese New Year" />
            <img
              src="https://jiliko.ph/resource/images/slot/05.jpg
                    " alt="Chinese New Year"
              class="img_fit"
            />
            <p class="mask">Chinese New Year</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/06.png" class="img_fit" alt="Money Coming"/>
            <img
              src="https://jiliko.ph/resource/images/slot/06.png
                    " alt="Money Coming"
              class="img_fit"
            />
            <p class="mask">Money Coming</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/07.jpg" class="img_fit" alt="Night Market" />
            <img
              src="https://jiliko.ph/resource/images/slot/07.jpg
                    " alt="Night Market"
              class="img_fit"
            />
            <p class="mask">Night Market</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/08.jpg" class="img_fit" alt="Queen Of The Sun" />
            <img
              src="https://jiliko.ph/resource/images/slot/08.jpg
                    " alt="Queen Of The Sun"
              class="img_fit"
            />
            <p class="mask">Queen Of The Sun</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/09.png" class="img_fit" alt="Charge Buffalo"/>
            <img
              src="https://jiliko.ph/resource/images/slot/09.png
                        " alt="Charge Buffalo"
              class="img_fit"
            />
            <p class="mask">Charge Buffalo</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/10.png" class="img_fit" alt="Mega Ace"/>
            <img
              src="https://jiliko.ph/resource/images/slot/10.png
                    " alt="Mega Ace"
              class="img_fit"
            />
            <p class="mask">Mega Ace</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/11.jpg" class="img_fit" alt="Super Ace"/>
            <img
              src="https://jiliko.ph/resource/images/slot/11.jpg
                    " alt="Super Ace"
              class="img_fit"
            />
            <p class="mask">Super Ace</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/12.jpg" class="img_fit" alt="Golden Empire"/>
            <img
              src="https://jiliko.ph/resource/images/slot/11.jpg
                    " alt="Golden Empire"
              class="img_fit"
            />
            <p class="mask">Golden Empire</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/13.jpg" class="img_fit" alt="Fortune Gems"/>
            <img
              src="https://jiliko.ph/resource/images/slot/13.jpg
                    " alt="Fortune Gems"
              class="img_fit"
            />
            <p class="mask">Fortune Gems</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/14.png" class="img_fit" alt="Boxing King" />
            <img
              src="https://jiliko.ph/resource/images/slot/14.png
                    " alt="Boxing King"
              class="img_fit"
            />
            <p class="mask">Boxing King</p>
          </a>
        </li>
        <li class="col-5 col-md-6">
          <a class="thumbimg" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
            <img data-cfsrc="resource/images/slot/15.jpg" class="img_fit" alt="Chinese New Year" />
            <img
              src="https://jiliko.ph/resource/images/slot/15.jpg
                    " alt="Chinese New Year"
              class="img_fit"
            />
            <p class="mask">Chinese New Year</p>
          </a>
        </li>
      </ul>
      <p class="p center title">
        Play the best online slot games <span class="w1">popular slot</span>{" "}
        games at JILIKO
      </p>
      <p class="p center ">
        Jiliko Casino is home to all popular online slot games you can think of.
        You will find all sorts of new or classic, popular or niche, and of
        course trending games. Jiliko slots games are divided into two
        subcategories: games with simple symbols and low payouts, and those with
        more complicated and high stakes symbols and higher payouts. The best
        thing about this place is that every player can enjoy their favorite
        slot no matter what type suits them.
      </p>
      <ul class="flex row">
        <li class="col-45">
          <div class="all-height">
            <div class="relate">
              {" "}
              <img
                data-cfsrc="resource/images/slot-img3.jpg"
                class="img_fit col-12" alt="Payline Slot"
              />
              <img
                src="https://jiliko.ph/resource/images/slot-img3.jpg"
                class="img_fit col-12" alt="Payline Slot"
              />
              <p class="mask w3">Payline Slot </p>
            </div>
            <div class="content bg-grey">
              <p class="p">
                The pay-line is one of the most prominent features of any slot
                machine. It determines how much each player can win, and it is
                designed to provide bettors with many payout opportunities at
                once. Slot machines usually have only one pay line, with
                three-reel slots having two. Slots in many cases tend to be very
                lucrative than the other types of games available on the market.
              </p>
              <p class="p">
                Every time a slot machine wins, the payout is determined by the
                number of lines, special symbols and even wild symbols that have
                been chosen below. The payouts that players can receive from
                each spin is dependent on the line count chosen by them during
                gambling.
              </p>
            </div>
          </div>
        </li>
        <li class="col-55">
          <div class="all-height">
            <div class="relate">
              {" "}
              <img
                data-cfsrc="resource/images/slot-img4.jpg"
                class="img_fit col-12" alt="Slot Symbols"
              />
              <img
                src="https://jiliko.ph/resource/images/slot-img4.jpg"
                class="img_fit col-12" alt="Slot Symbols"
              />
              <p class="mask w3"> Slot Symbols </p>
            </div>
            <div class="content bg-grey">
              <p class="p">
                Slot symbols are the core of any slot machine. They give you a
                base amount of winnings, without which the game would not be
                possible. Get the highest payouts with slot machines that offer
                combinations of standard and special symbols.
              </p>
              <p class="p">
                Some of the most popular slot machines have a wild symbol that
                replaces all other symbols on the reel. This feature can trigger
                special features and make it easier to win big. Wild symbols
                also make it easier to rack up bonuses and increase your
                winnings while still giving you a greater chance of hitting a
                jackpot!
              </p>
              <p class="p">
                Slot machines use a large number of standard and special symbols
                to pay off the winning combinations. While the basic rules for
                game play remain the same, slot games have developed in several
                unique ways over time, making them a great investment
                opportunity.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default MostPopular;
