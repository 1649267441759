import React from 'react'
import Hero from '../components/Slot/Hero'
import CasinoBrings from '../components/Slot/CasinoBrings'
import Popular from '../components/Slot/Popular'
import MostPopular from '../components/Slot/MostPopular'
import GameExperience from '../components/Slot/GameExperience'

const SlotPage = () => {
  return (
    <div>
      <Hero/>
      <CasinoBrings/>
      <Popular/>
      <MostPopular/>
      <GameExperience/>
    </div>
  )
}

export default SlotPage