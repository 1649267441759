import React from 'react'

const Popular = () => {
    return (
        <section class="bg-grey">
            <div class="container main center">
                <p class="p  title">Jiliko is home to all popular <span class="w1">online slots!</span></p>
                <p class="p">Jiliko Casino brings you one of the best online slots sites you can play on. Their range of
                    online slots games is both large and diverse. With over 1000 different titles, players can always
                    find a new slot game to enjoy here.</p>
                <p class="p">Slots are popular for their exclusive bonus features, superb graphics, high payouts, and
                    wide betting range. Play the hottest slots and live casino games with JILIKO, the most trusted
                    software provider on the market. such as CQ9, JILI, SE GAMING, Fa Chai, KA-Gaming etc.</p>
                <img src="https://jiliko.ph/resource/images/slot-img2.jpg" class="img_fit center-img" alt='images'/>
            </div>
        </section>
    )
}

export default Popular