import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("/");

  useEffect(() => {
    // Set the active link based on the current route
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <>
      <header>
        <div className="container ">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            className="logo inline"
          >
            <img src="/images/logo.webp" className="img_fit" alt="Jiliko" />
          </a>
          <a
            className="inline"
            href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img
              src="https://jiliko.ph/resource/images/fb-icon.png"
              className="img_fit"
              alt="facebook icon"
            />{" "}
          </a>
        </div>
        <nav>
          <div className="container ">
            <div id="nav-icon">
              <span></span>
              <span></span>
              <span></span>
            </div>

            <ul className="topnav flex flex-md flex-container">
              <li className={activeLink === "/" ? "active" : ""}>
                <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
                  Jiliko
                </NavLink>
              </li>
              <li className={activeLink === "/joinJiliko" ? "active" : ""}>
                <NavLink to="/joinJiliko">Join Jiliko</NavLink>
              </li>
              <li className={activeLink === "/slot" ? "active" : ""}>
                <NavLink to="/slot">Slot</NavLink>
              </li>
              <li className={activeLink === "/fisher-games" ? "active" : ""}>
                <NavLink to="/fisher-games">Fisher Games</NavLink>
              </li>
              <li className={activeLink === "/casino" ? "active" : ""}>
                <NavLink to="/casino"> Live Casino </NavLink>
              </li>
              <li className={activeLink === "/sport" ? "active" : ""}>
                <NavLink to="/sport"> Sport</NavLink>
              </li>
              <li className={activeLink === "/sabong" ? "active" : ""}>
                <NavLink to="/sabong"> Sabong</NavLink>
              </li>
              <li className={activeLink === "/arcade" ? "active" : ""}>
                <NavLink to="/arcade"> Arcade Games </NavLink>
              </li>
              <li className={activeLink === "/promotion" ? "active" : ""}>
                <NavLink to="/promotion"> Promotion </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
