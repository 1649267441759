import React from 'react'

const TakeFew = () => {
  return (
   <>
      <section>
      <div class="container main ">
        <div class="flex flex-md ">
          <div class="col-6 col-md-12">
            <p class="title p mt-2"> Just take a few minutes to<span class="w1"> Enjoy JILIKO Games </span> </p>
            <p class="p mb-3"> If you are looking for a safe and trustworthy gambling site in the Philippines, Jiliko is
              your answer. When you register an account at Jiliko.bet , you can enjoy our top-notch games, bonuses and
              promotions within a few seconds!</p>
          </div>
          <div class="col-6 col-md-12"><img src="https://jiliko.ph/resource/images/home-img2.jpg" class="img_fit" alt='images'/> </div>
        </div>
        <ul class="flex home-step row center">
          <li class="col-3 col-md-12">
            <div class="content all-height bg-grey">
              <p class="tt w1">step <span class="num">01</span></p>
              <p class="w1 w2 p">Register</p>
              <p class>Enter basic data to easily create a jiliko account </p>
            </div>
          </li>
          <li class="col-3 col-md-12">
            <div class="content all-height bg-grey">
              <p class="tt w1">step <span class="num">02</span></p>
              <p class="w1 w2 p">Make a deposit</p>
              <p class>Jiliko support a variety of ways to make deposits, which is convenient and fast </p>
            </div>
          </li>
          <li class="col-3 col-md-12">
            <div class="content all-height bg-grey">
              <p class="tt w1">step <span class="num">03</span></p>
              <p class="w1 w2 p">Start to Play</p>
              <p class>Variety of games to play and win bonuses</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
   </>
  )
}

export default TakeFew