import React from 'react'
import Hero from '../components/Sport/hero'
import Excellent from '../components/Sport/Excellent'
import Offers from '../components/Sport/Offers'
import Start from '../components/Sport/Start'
import Easy from '../components/Sport/Easy'


const Sport = () => {
  return (
    <div>
        <Hero/>
        <main>
        <Excellent/>
        <Offers/>
        <Start/>
        <Easy/>
        </main>
    </div>
  )
}

export default Sport