import React from "react";

const Register = () => {
  return (
    <section class="join-bg bg-cover w-white">
      <div class="container main center">
        <p class="p  mt-3 title">
          Register your account today! and get{" "}
          <span class="w-main">JiliKO Bonus!</span>
        </p>
        <p class="p mb-3">
          Register with JILIKO, and you’re permanently entitled to win in the
          betting section or in the casino game. The bonus is linked to your
          account, so you can easily activate it after registering, and then
          start enjoying all the advantages available for active players.
        </p>
        <a class="btn w2" href="https://bj88ph.live/af/Zd946zCP/jiliko-bet">
          Sign up
        </a>
      </div>
    </section>
  );
};

export default Register;
