import React from "react";
import "../styles/arcade.css";

const Hero = () => {
  return (
    <div class="pagetop pagetop7">
      <div class="container ">
        <h2 class="page-title"> Sabong </h2>
      </div>
    </div>
  );
};

export default Hero;
