import React from "react";

const BreathingGames = () => {
  return (
    <div class="container main ">
      <div class="flex ">
        <div class="col-55 col-md-12">
          <p class="title  p">
            <span class="w1"> Online casino </span>are breathing new life into
            arcade games
          </p>
          <p class="p">
            If you were a kid back in the '70s and '80s, then you probably spent
            some time at an arcade. The term ‘arcade game’ refers to a type of
            entertainment machine found in North America, Europe and Oceania.
            These machines are a popular form of gambling and have characters or
            items on them that the player accomplishes a goal or objective with.
            These are distinct from games such as gambling which don’t require
            physical spaces but instead only an online presence.
          </p>
          <p class="p">
            JILIKO Casino software providers have breathed new life into these
            games by adding gambling elements, which allows players to enjoy
            their favorite old school arcade games from the comfort of their own
            homes while getting a little nostalgia at the same time.
          </p>
        </div>
        <div class="col-45 col-md-12 col-R">
          <img
            src="https://jiliko.ph/resource/images/arcade-img2.jpg"
            class="img_fit"
            alt="images"
          />
        </div>
      </div>
    </div>
  );
};

export default BreathingGames;
