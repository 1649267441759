import React from 'react'

const Excellent = () => {
  return (
    <section>
    <div class="container main ">
        <div class="flex flex-md flex-md-row-reverse">
            <div class="col-6 col-md-12 col-R">
                <p class="p title">JILIKO is an excellent<span class="w1"> sports betting site </span> </p>
                <p class="p">JILIKO is an excellent sports betting site , offers a wide range of sports betting
                    options. Whether you are looking for pre-match betting or live event betting, the odds
                    offered by this online bookmaker are competitive, providing something for everyone. The
                    homepage is clean and easy to navigate, with tabs for all of your areas of interest. Bets in
                    the “Live” section are offered on almost any sport you can think of. </p>
            </div>
            <div class="col-6 col-md-12"><img data-cfsrc="resource/images/sport-img1.png"
                    class="img_fit sport-img1 img-hidden" alt='images'/><img
                    src="	https://jiliko.ph/resource/images/sport-img1.png" class="img_fit sport-img1" alt='images'/> </div>
        </div>
    </div>
</section>
  )
}

export default Excellent