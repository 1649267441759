import React from "react";

const SabongIs = () => {
  return (
    <section class="bg-grey">
      <div class="container ">
        <div class="flex flex-md flex-md-row-reverse">
          <div class="col-55 col-md-12 col-R">
            <p class="p title mt-3">
              What is <span class="w1">Sabong?</span>{" "}
            </p>
            <p class="p">
              Sabong or cockfighting is a Filipino blood sport where two birds
              are placed in an arena to fight each other. The purpose of sabong
              is to determine who is the better fighter by fighting back and
              forth for several rounds, until either one bird loses its feathers
              or both birds stop fighting. Sabong activities are now regulated
              by Local Government Units (LGUs) while eSabong Online is
              controlled by PAGCOR.{" "}
            </p>
            <p class="p">
              Sabong is a traditional sport in the Philippines which involves
              two roosters. Pick a rooster, and then fight back and forth to
              determine the better bird. Through this app, you can now
              participate in eSabong and have fun while earning rewards!
            </p>
          </div>
          <div class="col-45 col-md-12">
            <img
              src="https://jiliko.ph/resource/images/sabong-img2.jpg"
              class="img_fit"
              alt="images"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SabongIs;
