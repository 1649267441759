import React from "react";
import Hero from "../components/Arcade/Hero";
import VarietyGames from "../components/Arcade/VarietyGames";
import MostPopular from "../components/Arcade/MostPopular";
import BreathingGames from "../components/Arcade/BreathingGames";
import Exciting from "../components/Arcade/Exciting";

const Arcade = () => {
  return (
    <div>
      <Hero />
      <main>
        <VarietyGames />
        <MostPopular/>
        <BreathingGames/>
        <Exciting/>
      </main>
    </div>
  );
};

export default Arcade;
