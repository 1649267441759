import React from 'react'

const GreatTime = () => {
  return (
    <section class="casino-bg bg-cover w-white">
    <div class="container main center">
        <p class="p mt-3 title">We are sure that you will have a <span class="w-main"> great time at JILIKO
            </span></p>
        <p class="p mb-3">Live casinos can be found at most online gambling sites today and offer players a
            classic casino setting that mimics a land-based casino. Jiliko live casino gives you the opportunity
            to enjoy playing your favorite games from home with a very nice ambience. They have become more
            popular in recent years, as they offer players something different to what they can find at ordinary
            online casinos – namely, the chance to interact with real dealers who make bets directly with you at
            your table.</p>
    </div>
</section>
  )
}

export default GreatTime