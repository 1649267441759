import React from 'react'
import '../styles/fishergames.css'; 

const Hero = () => {
  return (
    <div class="pagetop pagetop3">
    <div class="container ">
        <h2 class="page-title"> Fisher Games </h2>
    </div>
</div>
  )
}

export default Hero