import React from "react";
import Banner from "../components/banner";
import Advantages from "../components/Advantages";
import Entertainment from "../components/Entertainment";
import Enjoy from "../components/Enjoy";
import TakeFew from "../components/takeFew";

const Home = () => {
  return (
    <div>
      <Banner />
      <Advantages />
      <Entertainment />
      <TakeFew />
      <Enjoy />
    </div>
  );
};

export default Home;
