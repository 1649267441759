import React from 'react'
import '../styles/slot.css'; 

const Hero = () => {
  return (
    <div class="pagetop pagetop2">
        <div class="container ">
            <h2 class="page-title"> Slot </h2>
        </div>
    </div>
  )
}

export default Hero