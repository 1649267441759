import React from 'react'

const Offers = () => {
  return (
    <section class="bg-grey">
            <div class="container main">
                <p class="p center title">JILIKO offers a wide variety of <span class="w1"> Sports betting options
                    </span></p>
                <p class="p center">Bets in the “Live” section are offered on almost any sport you can think of, this
                    includes horse racing, football, rugby, basketball and cricket just to name a few. Regardless of the
                    sport or event that you want to play, You can choose between pre match and live betting depending on
                    your tastes and preferences.</p>
                <div class="flex bg-w mb-2">
                    <div class="col-3 col-md-12">
                        <img data-cfsrc="resource/images/sport-img2.jpg" class="img_fit col-12  img-hidden"
                           alt="images"/><img
                            src="https://jiliko.ph/resource/images/sport-img2.jpg" class="img_fit col-12" alt="images"/>
                    </div>
                    <div class="col-9 col-md-12 ">
                        <div class="content">
                            <span class="w1 w2"> Football </span>
                            <p class="p">Football betting is perhaps the most popular form of sports betting in the
                                Philippines given that there are more than 100,000 sporting activities throughout the
                                year. This explains why many people bet on this game, seeing as it brings them joy
                                through profits</p>
                        </div>
                    </div>
                </div>
                <div class="flex bg-w mb-2">
                    <div class="col-3 col-md-12">
                        <img data-cfsrc="resource/images/sport-img3.jpg" class="img_fit col-12 img-hidden"
                           alt="images"/><img
                            src="https://jiliko.ph/resource/images/sport-img3.jpg" class="img_fit col-12" alt="images"/>
                    </div>
                    <div class="col-9 col-md-12 ">
                        <div class="content">
                            <span class="w1 w2"> Basketball </span>
                            <p class="p">Basketball is a fast-paced and thrilling game, making it the perfect way to
                                pass some time. You can bet on basketball games through various established betting
                                sites, or even directly with friends at work or school!</p>
                        </div>
                    </div>
                </div>
                <div class="flex bg-w mb-2">
                    <div class="col-3 col-md-12">
                        <img data-cfsrc="resource/images/sport-img4.jpg" class="img_fit col-12 img-hidden"
                           alt="images"/><img
                            src="https://jiliko.ph/resource/images/sport-img4.jpg" class="img_fit col-12" alt="images"/>
                    </div>
                    <div class="col-9 col-md-12 ">
                        <div class="content">
                            <span class="w1 w2"> Tennis </span>
                            <p class="p">Tennis is one of the most popular and widely played sports in the Philippines,
                                so it’s no surprise that we offer plenty of odds for you to bet on. If you’re looking to
                                place a bet on an individual match, or which player will win a Grand Slam event - like
                                Wimbledon, Australian Open and US Open - then JILIKO have options for you.</p>
                        </div>
                    </div>
                </div>
                <div class="flex bg-w mb-2">
                    <div class="col-3 col-md-12">
                        <img data-cfsrc="resource/images/sport-img5.jpg" class="img_fit col-12 img-hidden"
                           alt="images"/><img
                            src="https://jiliko.ph/resource/images/sport-img5.jpg" class="img_fit col-12" alt="images"/>
                    </div>
                    <div class="col-9 col-md-12 ">
                        <div class="content">
                            <span class="w1 w2"> Boxing</span>
                            <p class="p">Boxing is a very popular sport and there are many fans around the world. This
                                is especially in the Philippines where it is the national sport of our country. There
                                are many famous boxing champions from the Philippines such as Manny Pacquiao who have
                                gained international fame because of their prowess on this competitive sport.</p>
                        </div>
                    </div>
                </div>
                <p class="p">The most popular sports that you can bet on include football, tennis and golf. However,
                    there are many more sporting events to bet on. You can bet on ice skating, martial arts, rugby and
                    cricket, among others</p>
            </div>
        </section>
  )
}

export default Offers