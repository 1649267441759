import React from 'react'
import '../styles/sabong.css'; 

const Hero = () => {
  return (
    <div class="pagetop pagetop6">
        <div class="container ">
        <h2 class="page-title"> Sabong </h2>
        </div>
        </div>
  )
}

export default Hero