import React from "react";

const Entertainment = () => {
  return (
    <div>
      {" "}
      <section class="home-bg1 bg-cover w-white main center">
        <div class="container">
          <p class="title p mt-2 ">
            Jiliko Online Casino, an
            <span class="w-main"> exciting entertainment platform</span>
          </p>
          <p class="p">
            To make the best game experience and give you better odds, JILIKO
            cooperate with the most trusted software vendor such as KA-Gaming,
            JILI, Fa Chai etc., you can fine Slot Games,{" "}
            <a
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              target="_blank"
              rel="noreferrer"
            >
              live Casino
            </a>
            , Poker, Baccarat, Roulette, Fish Shooter and other games in Jiliko,
            guaranteed to bring all players a perfect gaming experience.
          </p>
        </div>
        <ul class="flex home-games row center">
          <li class="col-3 col-lg-6 col-sm-12 ">
            <div class="bg-b all-height">
              <img
                src="https://jiliko.ph/resource/images/home-slot.jpg"
                class="img_fit col-12"
                alt="images"
              />
              <div class="content ">
                <p class="tt w-main p">Jiliko Slot </p>
                <p class>
                  Jiliko Casino is a place to go if you love slots. We’ve got a
                  choice of hundreds of popular games that you can play, ranging
                  from the most classic winning lines to the most modern slot
                  machines. You’ll find our games to be highly reliable and fun,
                  so it’s easy to see why Jiliko is one of the best casinos out
                  there today.
                </p>
              </div>
            </div>
          </li>
          <li class="col-3 col-lg-6 col-sm-12 ">
            <div class="bg-b all-height">
              <img
                src="https://jiliko.ph/resource/images/home-fish.jpg"
                class="img_fit col-12"
                alt="images"
              />
              <div class="content">
                <p class="tt w-main p">Jiliko Fisher Games </p>
                <p class>
                  JiliKo Fish Shooting games are a unique, thrilling and very
                  popular gambling activity. They have many advantages over the
                  traditional slots and roulette games. Fish table games can be
                  played for free or for real money at jiliko. With fish games
                  gambling, there’s more action, more control, bigger rewards
                  and a whole new way to play like no other online casino game!{" "}
                </p>
              </div>
            </div>
          </li>
          <li class="col-3 col-lg-6 col-sm-12 ">
            <div class="bg-b all-height">
              <img
                src="https://jiliko.ph/resource/images/home-casino.jpg"
                class="img_fit col-12"
                alt="images"
              />
              <div class="content">
                <p class="tt w-main p"> Jiliko Live Casino </p>
                <p class>
                  With{" "}
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
                    target="_blank"
                  >
                    Jiliko live casino games
                  </a>
                  , you can play real games wherever you are. A direct video
                  link allows you to see everything going on in the game as if
                  you were playing at a real table. However it’s just one way to
                  enjoy our live casino games; there are other channels through
                  which you can get involved in these popular gambling
                  platforms.
                </p>
              </div>
            </div>
          </li>
          <li class="col-3 col-lg-6 col-sm-12 ">
            <div class="bg-b all-height">
              <img
                src="	https://jiliko.ph/resource/images/home-slot.jpg"
                class="img_fit col-12"
                alt="images"
              />
              <div class="content ">
                <p class="tt w-main p">Jiliko Sport </p>
                <p class>
                  JILIKO offers the best types of sports betting, suitable for
                  everyone. Whether you are looking to bet on a simple
                  win-or-draw game or place more complex options such as a
                  multi-risk bets, you will find our sportsbook has a wide range
                  of betting offers.{" "}
                </p>
              </div>
            </div>
          </li>
          <li class="col-3 col-lg-6 col-sm-12 ">
            <div class="bg-b all-height">
              <img
                src="	https://jiliko.ph/resource/images/home-slot.jpg"
                class="img_fit col-12"
                alt="images"
              />
              <div class="content">
                <p class="tt w-main p">JILIKO Sabong </p>
                <p class>
                  Enjoy playing sabong online in the Philippines with Sabong
                  JILIKO. This online platform makes it easier for you to
                  participate in sabong betting legally and conveniently
                  throughout the country. Our services are straightforward and
                  convenient to use, so that you can easily navigate how to make
                  bets on cockfighting in our platform.
                </p>
              </div>
            </div>
          </li>
          <li class="col-3 col-lg-6 col-sm-12 ">
            <div class="bg-b all-height">
              <img
                src="	https://jiliko.ph/resource/images/home-arcade.jpg"
                class="img_fit col-12"
                alt="images"
              />
              <div class="content">
                <p class="tt w-main p"> JILIKO Arcade Games </p>
                <p class>
                  If you are looking for a gaming site that can provide you with
                  a variety of online casinos, then JILIKO Arcade Games have
                  what you need. We offer gambling games that are both new and
                  old, we have them all. You will also be able to find games
                  that suit your needs and interests.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Entertainment;
