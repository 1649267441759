import React from 'react'
import '../styles/fishergames.css'; 

const ShootingGames = () => {
  return (
    <section>
            <div class="container main center">
                <p class="p center title">Play fun fish shooting games on <span class="w1">Jiliko</span></p>
                <p class="p">Fish shooting games are a fun way to play and earn rewards. The key concept behind fish
                    shooting games is simple: You shoot fish with cannons on different sides of the board. Players
                    choose which fish to shoot, and decide between killing small fish or large fish for bigger rewards.
                    Fish also enter the board frequently if you don’t keep track of whether they’re about to leave.</p>
            </div>
        </section>
  )
}

export default ShootingGames