import React from 'react'

 const Footer = () => {
  return (
    <footer>
    <div class="container">
      <p>Copyright © Jiliko - Best Bonuses Online Casino to Play JILI Slot in Philippines. All Rights Reserved.</p>
    </div>
  </footer>
  )
}

export default Footer;