import React from 'react'

const Dedicated = () => {
  return (
    <section>
    <div class="container main center">
        <p class="p center title">JILIKO dedicated to bringing you <span class="w1"> Live Casino Games </span>
        </p>
        <p class="p">You can play live casino games with real dealers and without having to leave your home.
            This is just what the Jiliko casino offered to you. However, even if you have no experience in games
            of chance and other similar actions that are based on luck, we still invite you to take a glance at
            our site and discover the latest casino games from our catalogue. You will find your favorite events
            on Jiliko at any moment you wish.</p>
        <img data-cfsrc="resource/images/casino-img1.jpg" class="img_fit center-img img-hidden"
             alt='images'/><img src="https://jiliko.ph/resource/images/casino-img1.jpg"
                class="img_fit center-img" alt='images'/>
    </div>
</section>
  )
}

export default Dedicated