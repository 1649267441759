import React from "react";

const Nopages = () => {
  return (
    <div>
      <div className="section-nopage d-flex justify-content-center align-items-center">Nopages </div>
    </div>
  );
};

export default Nopages;
