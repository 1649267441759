import React from "react";

const MostPopular = () => {
  return (
    <section class="bg-grey">
      <div class="container main center">
        <p class="p center title">
          <span class="w1"> Most Popular </span> Live Casino Games
        </p>
        <ul class="flex row center mb-3">
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/01.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/01.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Baccarat</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/02.jpg"
                class="img_fi img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/02.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Roulette</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/03.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/03.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Blackjack</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/04.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/04.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">DragonTiger</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              {/* <img
                data-cfsrc="resource/images/casino/05.jpg"
                class="img_fit"
                img-hidden
                alt="images"
              /> */}
              <img
                src="	https://jiliko.ph/resource/images/casino/05.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Texas Hold'em</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/06.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/06.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Mega Ball</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/07.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/07.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Sicbo</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/08.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/08.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Fantan</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/09.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/09.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Dream Catcher</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                data-cfsrc="resource/images/casino/10.jpg"
                class="img_fit img-hidden"
                alt="images"
              />
              <img
                src="	https://jiliko.ph/resource/images/casino/10.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Deal Or No Deal</p>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MostPopular;
