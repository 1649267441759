import React from "react";

const MostPopular = () => {
  return (
    <div>
      {" "}
      <section class="bg-grey">
        <div class="container main">
          <p class="p title center">
            <span class="w1">Most popular</span> Arcade Games{" "}
          </p>
          <ul class="flex row center ">
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/01.jpg"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Bingo</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/02.png"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Super Bingo</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/03.png"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Royal Fishing</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/04.jpg"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Crazy Hunter</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/05.jpg"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Dinosaur Tycoon</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/06.jpg"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">MoneyBingo</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/07.png"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Bingo Carnaval</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/08.png"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Number King </p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/09.png"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Caishen Dao</p>
              </a>
            </li>
            <li class="col-5 col-md-6">
              <a
                class="thumbimg"
                href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
              >
                <img
                  src="https://jiliko.ph/resource/images/arcade/10.jpg"
                  class="img_fit"
                  alt="images"
                />
                <p class="mask">Cock Fighting</p>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default MostPopular;
