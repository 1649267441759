import React from 'react'

const Discover = () => {
  return (
    <section>
    <div class="container main ">
        <div class="flex flex-md ">
            <div class="col-55 col-md-12 ">
                <p class="p title">Discover the<span class="w1"> best live Sabong game </span> in Philippines!
                </p>
                <p class="p">JILIKO Sabong Live is an online sabong game that you can enjoy right on your mobile
                    phone, no need to go anywhere or visit a real venue. JILIKO Sabong Live allows you to
                    directly interact with other players through a live broadcast as dozens of roosters battle
                    each other on screen. Our professional team ensures that every games we provide is clean and
                    fair. </p>
            </div>
            <div class="col-45 col-md-12"><img src="https://jiliko.ph/resource/images/sabong-img1.jpg"
                    class="img_fit" alt='images'/> </div>
        </div>
    </div>
</section>
  )
}

export default Discover