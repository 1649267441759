import React from "react";
import '../styles/sabong.css'; 

const Cockfighting = () => {
  return (
    <section class="sabong-bg bg-cover w-white">
      <div class="container main center">
        <p class="p  mt-3 title">
          {" "}
          You can bet on cockfighting online easily through{" "}
          <span class="w-main">JILIKO</span>{" "}
        </p>
        <p class="p mb-3">
          If you are a fan of cockfighting and want to place bets on the game,
          then you’re probably already familiar with sabong betting. The game
          has evolved over time as it merged with modern betting platforms,
          including the use of e-money and online betting sites. You can bet on
          cockfighting online easily through JILIKO. It's a convenient and
          easier way to learn how to gamble on cockfights.
        </p>
      </div>
    </section>
  );
};

export default Cockfighting;
