import React from "react";

const FishingGames = () => {
  return (
    <section class="bg-grey">
      <div class="container main center">
        <p class="p center title">
          JILIKO’s <span class="w1"> Fishing Games </span>
        </p>
        <ul class="flex row center mb-3">
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/01.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Mega Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/02.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Happy Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/03.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Royal Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/04.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Crazy Hunter</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/05.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Dinosaur Tycoon</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/06.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Jackpot Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/07.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Bombing Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/08.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Dragon Treasure</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/09.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Secret Treasure</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/10.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Boom Legend</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/11.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Fierce Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/12.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Dragon Fortune</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/13.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">All-Star Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/14.jpg"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Cai Shen Fishing</p>
            </a>
          </li>
          <li class="col-5 col-md-6">
            <a
              class="thumbimg"
              href="https://bj88ph.live/af/Zd946zCP/jiliko-bet"
            >
              <img
                src="https://jiliko.ph/resource/images/fish/15.png"
                class="img_fit"
                alt="images"
              />
              <p class="mask">Golden Dragon</p>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default FishingGames;
