import React from 'react'
import Herosection from '../components/JoinJiliko/Hero'
import Join from '../components/JoinJiliko/join'
import CreateNew from '../components/JoinJiliko/CreateNew'
import Deposit from '../components/JoinJiliko/Deposit'
import Register from '../components/JoinJiliko/Register'

const JoinJiliko = () => {
  return (
    <div>
      <Herosection/>
      <Join/>
      <CreateNew/>
      <Deposit/>
      <Register/>
    </div>
  )
}

export default JoinJiliko