import React from "react";

const Start = () => {
  return (
    <div class="container main ">
      <div class="flex flex-md ">
        <div class="col-6 col-md-12">
          <p class="title  p">
            How To start Betting on <span class="w1">JILIKO Sports</span>{" "}
          </p>
          <p class="p">
            Sport betting is now simpler with the web. You can now place a bet
            just by hopping online, with minimum time and effort. Before you get
            down to betting, consider the odds for each of your selected sports.
            Are there any sports that you should avoid betting on because the
            odds are uncompetitive? Or alternatively, is there any sport in
            which you can predict with confidence who will win based on factors
            such as previous form or current position in a table (or league)?
          </p>
          <p class="p">
            You can set your own odds, study each team’s history and stats,
            compare odds from different bookmakers, and even read what other
            people are saying about the match.
          </p>
        </div>
        <div class="col-6 col-md-12 col-R">
          <img
            data-cfsrc="resource/images/sport-img6.jpg"
            class="img_fit img-hidden"
            alt="images"
          />
          <img
            src="https://jiliko.ph/resource/images/sport-img6.jpg"
            class="img_fit col-12"
            alt="images"
          />{" "}
        </div>
      </div>
    </div>
  );
};

export default Start;
