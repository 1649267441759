import React from 'react'
import '../styles/sports.css'; 

const Easy = () => {
  return (
    <section class="sport-bg bg-cover w-white">
            <div class="container main center">
                <p class="p mt-3 title"> <span class="w-main">JILIKO </span> makes it easy to bet on the go</p>
                <p class="p mb-3">JILIKO offer a wide range of sports betting options. If you're new to sports betting,
                    we offer simple options. If you're an experienced bettor and want to try something different, we
                    have plenty of space for you to put money on multiple bets in one game or on more complex option
                    types.You can take advantage of the bonuses, promotions and great customer service that JILIKO
                    offers.</p>
            </div>
        </section>
  )
}

export default Easy