import React from "react";
import "../styles/arcade.css";

const Exciting = () => {
  return (
    <section class="arcade-bg bg-cover w-white">
      <div class="container main center">
        <p class="p mt-3 title">
          A new way to enjoy exciting games at{" "}
          <span class="w-main">JILIKO </span>{" "}
        </p>
        <p class="p mb-3">
          Jiliko casino is an online gambling site that allows you to play
          online casino games from anywhere, as long as you have a smooth
          internet connection. You can access our site from anywhere, as long as
          you've got a solid connection and can reach our servers, which are one
          of the best places to play casino games. At Jiliko you'll get access
          to the best Arcade Games on any platform and for any device you need.
        </p>
      </div>
    </section>
  );
};

export default Exciting;
