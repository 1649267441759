import React from 'react'

const Join = () => {
  return (
    <section>
      <div class="container main">
        <p class="title center p">Join Jiliko now, experience <span class="w1">the best online casino </span> in the
          Philippines.</p>
        <div class="flex flex-md flex-md-row-reverse">
          <div class="col-6 col-md-12 col-R">
            <p class="p">JILIKO Online Casino is a popular gambling website that has been established since 2014. We
              offer the best casino games, and a high quality, user-friendly platform for our customers to enjoy a safe
              and secure gambling experience every day.</p>
            <p class="p">You can play unlimited slots, blackjack, roulette, baccarat, craps and many other exciting
              casino games at JILIKO. Our friendly customer service team is available in English and Tagalog 24/7 to
              assist you with all your queries.</p>
          </div>
          <div class="col-6 col-md-12"><img src="https://jiliko.ph/resource/images/join-img1.jpg" class="img_fit" alt='images'/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Join