import React from "react";

const VarietyGames = () => {
  return (
    <section>
      <div class="container main ">
        <div class="flex flex-md flex-md-row-reverse ">
          <div class="col-6 col-md-12  col-R">
            <p class="p title">
              JILIKO Casino offers a variety of{" "}
              <span class="w1"> Arcade Games </span>{" "}
            </p>
            <p class="p">
              JILIKO is your one stop solution to playing the best in online 2D
              arcade games. We have a variety of games that you can enjoy at our
              site and this includes several poker and roulette games. You can
              also play scratch cards, bingo and even slots if that's what you
              prefer. To play your favorite game, simply sign up and make your
              first deposit today!
            </p>
          </div>
          <div class="col-6 col-md-12">
            <img
              src="https://jiliko.ph/resource/images/arcade-img1.jpg"
              class="img_fit"
              alt="images"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VarietyGames;
