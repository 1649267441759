import React from 'react'

const Deposit = () => {
  return (
    <div class="container main ">
    <div class="flex flex-md">
      <div class="col-55 col-md-12">
        <p class="p  title">Deposit is convenience and security, <span class="w1">all transactions are super
            fast!</span> </p>
        <p class="p">At Jiliko Casino, players in the Philippines can choose from several deposit and withdrawal
          methods. For instance, GCash or local banking are some of the possible payment solutions. You can make
          deposits and withdrawals with credit cards, skins betting, bank transfers or with other payment methods. The
          interface is user-friendly and intuitive enough for anyone to access the site.</p>
      </div>
      <div class="col-45 col-md-12 col-R"><img src="https://jiliko.ph/resource/images/join-img4.jpg" class="img_fit" alt='images' />
      </div>
    </div>
  </div>
  )
}

export default Deposit