import React from 'react'
import '../styles/slot.css'; 

const GameExperience = () => {
  return (
    <section class="slot-bg bg-cover w-white">
    <div class="container main center">
        <p class="p mt-3 title">JILIKO gives you the <span class="w-main"> best Slot Games experience </span>
        </p>
        <p class="p mb-3"> JILIKO have a wide range of the most popular slot machine games. Don’t worry, because
            we’ve got the best features that will help you make the most of your money! We also have progressive
            jackpots and many other extras that guarantee our slots are a blast! So come on in and meet our
            exciting range of games!</p>
    </div>
</section>
  )
}

export default GameExperience