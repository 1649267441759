import React from 'react'
import '../styles/reasons.css';

const Reasons = () => {
    return (
        <div class="container main ">
            <p class="p center title">What are the reasons why people Choose <span class="w1"> Jiliko Casino? </span>
            </p>
            <div class="flex flex-md flex-md-row-reverse">
                <div class="col-6 col-md-12 col-R">
                    <p class="w2 w1 p">
                        <img data-cfsrc="resource/images/check.png" class="img_fit inline check-hide"
                        data-cfstyle="width: 30px;"   alt='images' />
                        <img src="https://jiliko.ph/resource/images/check.png" class="img_fit inline check-block" alt='images' />
                        Many types of games </p>
                    <p class="mb-2">If you’re a live casino fan, you’ll be very happy to know that most of the brands
                        offer different types of games.There are hundreds of live casino games available at the moment,
                        It can vary from blackjack variants to roulette, baccarat and many others. You should have no
                        problem finding the perfect games for your taste in this area.</p>
                    <p class="w2 w1 p">
                        <img data-cfsrc="resource/images/check.png" class="img_fit inline check-hide"
                        data-cfstyle="width: 30px;"   alt='images' />
                        <img src="https://jiliko.ph/resource/images/check.png" class="img_fit inline check-block"  alt='images' />
                        Multiple special Bonus </p>
                    <p class="mb-2">Enjoy all the best in real money online casino games with Jiliko. With exclusive
                        bonuses, high payouts and instant withdrawal processing we have everything you need to enjoy the
                        thrill of betting on your favorite casino games. There is nowhere better for real money live
                        casino action than Jiliko.</p>
                    <p class="w2 w1 p">
                        <img data-cfsrc="resource/images/check.png" class="img_fit inline check-hide"
                        data-cfstyle="width: 30px;"   alt='images' />
                        <img src="https://jiliko.ph/resource/images/check.png" class="img_fit inline check-block"  alt='images' />
                        Best live casino providers </p>
                    <p class="mb-2">Jiliko is the best place to enjoy your favourite Live Casino games. We have been
                        dedicated to providing quality gaming content since the inception of our company. Our games are
                        certified by a strict organization to guarantee the fairness of each game.</p>
                </div>
                <div class="col-6 col-md-12">
                    <img data-cfsrc="resource/images/casino-img2.jpg" class="img_fit check-hide"
                      alt='images' />
                    <img src="https://jiliko.ph/resource/images/casino-img2.jpg" class="img_fit" alt='images' /> </div>
            </div>
        </div>
    )
}

export default Reasons