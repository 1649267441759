import React from 'react'
import Hero from '../components/casino/Hero'
import Dedicated from '../components/casino/Dedicated'
import MostPopular from '../components/casino/MostPopular'
import Reasons from '../components/casino/Reasons'
import Footer from '../components/casino/GreatTime'
import GreatTime from '../components/casino/GreatTime'

const CasinoPage = () => {
  return (
    <div><Hero/>
    <main>
      <Dedicated/>
      <MostPopular/>
      <Reasons/>
      <GreatTime/>
      </main>
    </div>
  )
}

export default CasinoPage