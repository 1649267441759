import React from 'react'

const CasinoBrings = () => {
  return (
    <section>
    <div class="container main">
        <div class="flex flex-md flex-md-row-reverse">
            <div class="col-6 col-md-12 col-R">
                <p class="title  p">Jiliko Casino brings you the <span class="w1"> Best online slots</span> in the
                    market </p>
                <p class="p">JILIKO Online Casino is one of the most popular online casinos in the Philippines. In fact,
                    it has won numerous awards due to its excellence and high quality standards. You can enjoy the best
                    games on the market with their wide portfolio of games and extensive selection of slots you will not
                    find anywhere else.</p>
            </div>
            <div class="col-6 col-md-12">
                <img src="https://jiliko.ph/resource/images/slot-img1.jpg" class="img_fit" alt='images'/>
              </div>
              
        </div>
    </div>
</section>
  )
}

export default CasinoBrings