import React from 'react'

const Advantages = () => {
  return (
    <div>    <section class="main">
    <div class="container ">
      <div class="flex flex-md flex-md-row-reverse">
        <div class="col-55 col-md-12 col-R">
          <p class="title p mt-2"> <span class="w1">Advantages</span> of playing JILIKO</p>
          <p class="p mb-3"> Jiliko Online Casino is a well-known and highly respected online casino that has been
            serving players back home and abroad since it got online in 2022. The Philippine Amusement and Gaming
            Corporation (PACGOR) oversees the activities on this site so that players can be sure of fair play, 24/7
            customer support, loyalty programs and more. With stably fast connections and a variety of games to try
            out — including popular titles like Slots — it’s easy to see why Jiliko Bet is the gambling site of choice
            for many gamblers in the Philippines.</p>
        </div>
        <div class="col-45 col-md-12"> <img src="https://jiliko.ph/resource/images/banner-img.png" class="img_fit center-img" alt="jiliko-bet images" />
        </div>
      </div>
      <ul class="flex check-list row center">
        <li class="col-5 col-lg-6 col-md-12">
          <div class="content all-height">
            <img src="https://jiliko.ph/resource/images/check.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <img src="https://jiliko.ph/resource/images/check2.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <p class>Reputable Game Providers provide a lot of games </p>
          </div>
        </li>
        <li class="col-5 col-lg-6 col-md-12">
          <div class="content all-height">
            <img src="https://jiliko.ph/resource/images/check.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <img src="https://jiliko.ph/resource/images/check2.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <p class> No need to go through an agent, directly enter JILIKO BET </p>
          </div>
        </li>
        <li class="col-5 col-lg-6 col-md-12">
          <div class="content all-height">
            <img src="https://jiliko.ph/resource/images/check.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <img src="https://jiliko.ph/resource/images/check2.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <p class> It is easy to apply for membership </p>
          </div>
        </li>
        <li class="col-5 col-lg-6 col-md-12">
          <div class="content all-height">
            <img src="https://jiliko.ph/resource/images/check.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <img src="https://jiliko.ph/resource/images/check2.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <p class> 7/24 customer service team </p>
          </div>
        </li>
        <li class="col-5 col-lg-6 col-md-12">
          <div class="content all-height">
            <img src="https://jiliko.ph/resource/images/check.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <img src="https://jiliko.ph/resource/images/check2.png" class="img_fit center-img" alt="jiliko-bet images"/>
            <p class> Safe and reliable </p>
          </div>
        </li>
      </ul>
    </div>
  </section></div>
  )
}

export default Advantages