import React from "react";

const SideNav = () => {
  return (
    <>
      <div class="side_nav">
        <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
          <a class="a2a_button_facebook"></a>
          <a class="a2a_button_twitter"></a>
          <a class="a2a_button_pinterest"></a>
          <a class="a2a_button_linkedin"></a>
          <a class="a2a_button_line"></a>
        </div>
        <a id="BackTop">
          <img
            src="https://jiliko.ph/resource/images/top.png"
            class="img_fit"
            alt="images"
          />{" "}
        </a>
      </div>
    </>
  );
};

export default SideNav;
