import React from "react";
import Hero from "../components/sabong/Hero";
import Discover from "../components/sabong/Discover";
import SabongIs from "../components/sabong/SabongIs";
import OnlineSabong from "../components/sabong/OnlineSabong";
import Cockfighting from "../components/sabong/Cockfighting";

const Sabong = () => {
  return (
    <div>
      <Hero />
      <main>
        <Discover/>
        <SabongIs/>
        <OnlineSabong/>
        <Cockfighting/>
      </main>
    </div>
  );
};

export default Sabong;
